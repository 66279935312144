import { Component, inject } from '@angular/core'
import { Store } from '@ngrx/store'
import { ActivatedRoute, Router } from '@angular/router'

import { BaseArticleComponent } from 'src/app/shared/components/base-article-component/base-article-component'
import { AssetSale } from '../asset-sale-article.model'
import { MixpanelService } from 'src/app/services/mixpanel.service'
import { AsyncPipe } from '@angular/common'
import { AssetSaleViewComponent } from '../../../../shared/components/asset-sale-view/asset-sale-view.component'
import { NgScrollbarModule } from 'ngx-scrollbar'

@Component({
    selector: 'app-asset-article',
    templateUrl: './asset-article.component.html',
    styleUrls: ['./asset-article.component.scss'],
    standalone: true,
    imports: [NgScrollbarModule, AssetSaleViewComponent, AsyncPipe]
})
export class AssetArticleComponent  extends BaseArticleComponent<AssetSale> {
    constructor() {
        super()
        this.area =  'data/asset-sales'
    }
}
